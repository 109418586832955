import * as React from 'react';
import { navigate, Link } from "gatsby"
import { 
    Button,
	Grid,
    Paper,
	Typography,
} from '@mui/material';


const blackButton = {
	fontFamily: 'Quicksand',
	fontSize: '18px',
	lineHeight: '24px',
	fontWeight: "700",
	backgroundColor: '#000000',
	width: '100%',
	borderRadius: '50px',
	padding: '8px'
}
const listStyle = {
    fontFamily: 'Quicksand',
    fontWeight: 500,
    fontSize: '16px',
}

export function ContentPaper(props) {
    return (
        <Paper style={{padding:'24px'}}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <img
                        alt={props.imgAlt}
                        style={{width: '100%', height: 'auto', minHeight: '320px'}}
                        src={props.imgScr}
                    />
                    <Button style={blackButton} onClick={() => props.onClick} variant="contained">{props.buttonText}</Button>
                </Grid>
                <Grid item xs={8}>
                    <Typography fontFamily={"Quicksand"} fontWeight={"700"} fontSize={22} color={(props.clickable ? "#2C90CB" :"#000000")}> {props.title} </Typography>
                    {props.descriptionList &&
                        <>
                        <ul>
                            {props.descriptionList.map((listContent, index) => 
                                <li key={`listContent${index}`} style={listStyle}>
                                    {listContent}
                                </li>
                            )
                            }
                        </ul>
                        </>
                    }
                    <Typography fontFamily={"Quicksand"} fontWeight={"500"} textAlign={'justify'} fontSize={14} color={"#000000"}>{props.description} </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default function PapProjectSelectionCard(props){
    return (
        <>
        {props.route ?
            <Link style={{textDecoration: "none"}} to={props.route}>
                <ContentPaper 
                    title={props.title} 
                    description={props.description}
                    descriptionList={props.descriptionList}
                    buttonText={props.buttonText}
                    onClick={props.onClick}
                    clickable={props.route}
                    imgScr={props.imgScr}
                    imgAlt={props.imgAlt}
                />
            </Link>
        :
            <ContentPaper 
                title={props.title} 
                description={props.description} 
                descriptionList={props.descriptionList}
                buttonText={props.buttonText}
                onClick={props.onClick}
                clickable={props.route}
                imgScr={props.imgScr}
                imgAlt={props.imgAlt}
            />
        }
        </>
    )
}