import * as React from 'react';
import { navigate, Link } from "gatsby"
import { 
	Box,
	IconButton,
	Grid,
	Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PapProjectSelectionCard from 'components/pap-project-selection-card.js';
import PapFooter from 'components/pap-footer.js';
import Anaxagoras from "images/anaxagoras.png";
import Aristoteles from "images/aristoteles.png";
import Hipatia from "images/hipatia.png";
import Pitagoras from "images/Pitagoras.png";
import Socrates from "images/socrates.png";
import LogoDowinWhite from "images/logodowinWhite.png";
import { auth } from '../../../firebase-info';

// styles
const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '56px',
	margin: '16px',
	marginTop: '0px',
}
const imageContainer = {
	height: '36.7px',
}
const headerMargin = {
	marginBottom: '20px',
	marginLeft: '16px',
	display: 'inline-block',
	verticalAlign: 'middle',
}
const logout = {
	marginBottom: '20px',
	marginLeft: 'auto',
	verticalAlign: 'middle',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const verticalPadding20 = {
	paddingTop: '20px',
	paddingBottom: '20px',
}
const heavyBlue = {
	backgroundColor: '#2C90CB',
}
const headerLink = {
	textDecoration: 'none',
	color: '#FFF',
	fontWeight: 500,
}


// markup
const SelectProject = () => {
	const [loading, setLoading] = React.useState(true)

	function checkLogin() {
		if (auth.currentUser === null) {
			setLoading(true);
		   	navigate("/solicitants");
		   	return;
		}

		setLoading(false);
	};
	
	React.useEffect(() => {
		checkLogin();
	}, []);

	function performLogout() {
		auth.signOut();
		navigate("/");
	}

	return (
		<main style={{backgroundColor: loading ? '#FFF' : '#000', height: '100%'}}>
			{loading ? 
				<></>
				:
				<>
					<Box style={pageStyles}>
						<Box style={heavyBlue}>
							<Grid container spacing={2} style={topBar}>
								<Grid item xs={11}>
									<Link to="/">
										<img
											alt="dowin Logo"
											style={imageContainer}
											src={LogoDowinWhite}
										/>
									</Link>
									<Box style={headerMargin}>
										<Typography fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"}> Organizaciones y solicitantes </Typography>
									</Box>
									<Box style={headerMargin}>
										<Link  style={headerLink} fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"} to={"/dashboard"}> Mis proyectos </Link>
									</Box>
									<Box style={headerMargin}>
										<Link style={headerLink} fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"} to={"/profile"}> Mi perfil </Link>
									</Box>
								</Grid>
								<Grid item xs={1}>
									<IconButton style={logout} aria-label="Cerrar sesión" size="large">
										<LogoutIcon onClick={performLogout} fontSize="inherit" style={{fill: '#FFFFFF'}}/>
									</IconButton>
								</Grid>
							</Grid>
							<Grid style={verticalPadding40} justifyContent={"center"} container spacing={2}>
								<Grid item xs={10}>
                                    <Typography fontFamily={"Quicksand"} pb={"12px"} fontWeight={500} fontSize={28} color={"#FFFFFF"}>Solicita una campaña de donaciones para un proyecto solidario: </Typography>
								</Grid>
								<Grid style={verticalPadding20} justifyContent={"center"} container spacing={2}>
									<Grid item xs={5}>
										<PapProjectSelectionCard
											title={"Proyecto Sócrates"} 
											descriptionList={[
												'Se recompensa a todos los donantes',
												'Vuestra organización se encarga de elegir y gestionar la recompensa',
											]}
											description={"Donación durante un tiempo determinado con recompensa solidaria condicionada. Para incentivar y premiar a todos vuestros donantes, podéis comprar uno o varios décimos de lotería y, en caso de que toque, recompensar a todos vuestros donantes con una suculenta recompensa por su generosidad. Los donantes no son generosos para ganar nada, pero su generosidad puede tener premio."} 
											buttonText={"Solicitar"}
											onClick={() => navigate("/project/creation/socrates")}
											route={"/project/creation/socrates"}
											imgScr={Socrates}
											imgAlt={"sample"}
										/>
									</Grid>
									<Grid item xs={5}>
										<PapProjectSelectionCard
											title={"Proyecto Aristóteles"}
											descriptionList={[
												'Se recompensa a todos los donantes',
												'Desde dowin nos encargamos de elegir y gestionar la recompensa',
											]}
											description={"Donación durante un tiempo determinado con recompensa solidaria organizada por el equipo de dowin. No os preocupéis por incentivar y premiar a todos vuestros donantes, ya que nosotros desde el equipo de dowin nos encargamos de todo el proceso para que os centréis únicamente en conseguir donantes. Compraremos uno o varios décimos de lotería y, en caso de que toque, recompensaremos a todos vuestros donantes con una suculenta recompensa por su generosidad."} 
											buttonText={"Solicitar"}
											onClick={() => navigate("/project/creation/aristoteles")}
											route={"/project/creation/aristoteles"}
											imgScr={Aristoteles}
											imgAlt={"sample"}
										/>
									</Grid>
								</Grid>
								<Grid style={verticalPadding20} justifyContent={"center"} container spacing={2}>
									<Grid item xs={5}>
										<PapProjectSelectionCard
											title={"Proyecto Hipatia"} 
											descriptionList={[
												'Se recompensa a uno o varios donantes mediante un sorteo ante notario',
												'Vuestra organización se encarga de elegir y gestionar la recompensa',
											]}
											description={"Donación durante un tiempo determinado son sorteo ante notario. Para incentivar y premiar a todos vuestros donantes, podéis hacer un sorteo con un premio elegido por vosotros o un regalo que os ceda un patrocinador para todos vuestros donantes. El sorteo se realizará ante notario una vez que se haya obtenido la pertinente autorización de la Dirección General de la Ordenación del Juego y se haya pagado la tasa exigida."} 
											buttonText={"Solicitar"}
											onClick={() => navigate("/project/creation/hipatia")}
											route={"/project/creation/hipatia"}
											imgScr={Hipatia}
											imgAlt={"sample"}
										/>
									</Grid>
									<Grid item xs={5}>
										<PapProjectSelectionCard
											title={"Proyecto Anaxágoras"}
											descriptionList={[
												'Se recompensa a uno o varios donantes mediante un sorteo privado',
												'Vuestra organización se encarga de elegir y gestionar la recompensa',
											]}
											description={"Donación durante un tiempo determinado con sorteo privado. Para incentivar y premiar a vuestros donantes, podéis hacer un sorteo con un premio elegido por vosotros o un regalo que os ceda un patrocinador para vuestros donantes. Elegid un código que le debéis pasar a vuestro donantes para que puedan realizar la donación. El sorteo se realizará de manera privada una vez que se haya obtenido la pertinente autorización de la Dirección General de la Ordenación del Juego y se haya pagado la tasa exigida."} 
											buttonText={"Solicitar"}
											onClick={() => navigate("/project/creation/anaxagoras")}
											route={"/project/creation/anaxagoras"}
											imgScr={Anaxagoras}
											imgAlt={"sample"}
										/>
									</Grid>
								</Grid>
								<Grid style={verticalPadding20} justifyContent={"center"} container spacing={2}>
									<Grid item xs={5}>
										<PapProjectSelectionCard
											title={"Proyecto Pitágoras"} 
											descriptionList={[
												'Se recompensa a uno o varios donantes mediante un sorteo asociado al resultado de la lotería (o similar)',
												'Vuestra organización se encarga de elegir y gestionar la recompensa',
											]}
											description={"Donación durante un tiempo determinado con sorteo asociado al resultado de la lotería (o similar). Para incentivar y premiar a todos vuestros donantes, podéis hacer un sorteo con un premio elegido por vosotros o un regalo que os ceda un patrocinador para todos vuestros donantes. El ganador corresponderá a la papeleta que coincida con el número de Lotería Nacional o sorteo similar en la fecha seleccionada. Previamente deberán solicitar la pertinente autorización de la Dirección General de la Ordenación del Juego y se haya pagado la tasa exigida."} 
											buttonText={"Solicitar"}
											onClick={() => navigate("/project/creation/pitagoras")}
											route={"/project/creation/pitagoras"}
											imgScr={Pitagoras}
											imgAlt={"sample"}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Box>
					<PapFooter/>
				</>
			}
		</main>
	);
};

export default SelectProject;
